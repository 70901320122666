import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from 'react-audio-player';
import { FaPaperclip } from 'react-icons/fa'; // Add icon for attachment
import { useNavigate } from "react-router-dom";
import io from 'socket.io-client';
import happy from "../../assets/images/happy.png";
import send from "../../assets/images/send.jpeg";
import "./index.css";
const socket = io('https://villageconnect.codexsmart.com');

function Chat(props) {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [currentID, setCurrentID] = useState("");
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null); // For file preview
    const chatContainerRef = useRef(null);

    useEffect(() => {
        let useData = JSON.parse(localStorage.getItem("userData"));
        setCurrentID(useData?._id);

        const fetchMessages = async () => {
            try {
                const response = await fetch(`https://villageconnect.codexsmart.com/api/v1/Chat/messages`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setMessages(data);
                } else {
                    console.error('Failed to fetch messages:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        };

        fetchMessages();

        socket.on('receiveMessage', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        socket.on('receiveFileMessage', (fileMessage) => {
            setMessages((prevMessages) => [...prevMessages, fileMessage]);
        });

        return () => {
            socket.off('receiveMessage');
            socket.off('receiveFileMessage');
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const container = chatContainerRef.current;
            if (container) {
                setIsAtBottom(container.scrollHeight - container.scrollTop === container.clientHeight);
            }
        };

        const container = chatContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        const container = chatContainerRef.current;
        if (container) {
            if (isAtBottom) {
                container.scrollTop = container.scrollHeight;
            }
        }
    }, [messages]);

    const sendMessage = async () => {
        if (!message.trim()) return;

        const response = await fetch('https://villageconnect.codexsmart.com/api/v1/Chat/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: currentID, message }),
        });

        if (response.ok) {
            setMessage('');
        } else {
            console.error('Failed to send message');
        }
    };

    const sendFile = async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', currentID);
        formData.append('fileType', file.type);

        const response = await fetch('https://villageconnect.codexsmart.com/api/v1/Chat/sendFile', {
            method: 'POST',
            body: formData,
        });

        if (response.ok) {
            setFile(null);
            setFilePreview(null); // Clear the preview
        } else {
            console.error('Failed to send file');
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        if (selectedFile) {
            const fileUrl = URL.createObjectURL(selectedFile);
            setFilePreview(fileUrl);
        }
    };

    const bufferToString = (buffer) => {
        return String.fromCharCode.apply(null, new Uint8Array(buffer));
    };

    const getFileUrl = async (fileId) => {
        let filename;
        if (fileId.data && Array.isArray(fileId.data)) {
            filename = bufferToString(fileId.data);
        } else {
            filename = typeof fileId === 'string' ? fileId : fileId.filename;
        }

        if (!filename) {
            console.error('Filename is not available');
            return 'https://example.com/default-file.mp3'; // Fallback URL or handle the error
        }

        const url = `https://villageconnect.codexsmart.com/uploads/${filename}`;
        console.log(`Generated file URL: ${url}`);
        return url;
    };

    return (
        <>
            <div style={{ width: "100%", margin: "0 auto", color: "silver" }}><hr /></div>
            <div className="chat-main-container" ref={chatContainerRef}>
                {messages.map((msg, index) => (
                    <MessageBubble key={index} msg={msg} currentID={currentID} getFileUrl={getFileUrl} />
                ))}
            </div>
            <div style={{ width: "100%", margin: "auto", marginTop: "5%", color: "silver", marginBottom: "20px" }}><hr /></div>
            <div className="send-input-container">
                <input
                    type="text"
                    placeholder="پیام خود را اینجا تایپ کنید"
                    aria-label="Type your message here"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ flex: 1, marginRight: '8px' }}
                />
                <label htmlFor="file-upload" style={{ cursor: 'pointer', marginRight: '8px' }}>
                    <FaPaperclip size={24} color="#142C73" />
                    <input
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    />
                </label>
                {filePreview && (
                    <div style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>
                        {filePreview.endsWith('.pdf') ? (
                            <a href={filePreview} target="_blank" rel="noopener noreferrer">Preview PDF</a>
                        ) : filePreview.endsWith('.mp3') ? (
                            <ReactAudioPlayer
                                src={filePreview}
                                controls
                                style={{ maxWidth: '100px', marginRight: '8px' }}
                            />
                        ) : (
                            <img src={filePreview} alt="File preview" style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }} />
                        )}
                        <button onClick={sendFile} style={{ cursor: 'pointer', padding: '5px 10px', backgroundColor: '#142C73', color: '#fff', border: 'none', borderRadius: '5px' }}>Send</button>
                    </div>
                )}
                <img
                    src={send}
                    width="24px"
                    height="24px"
                    alt="Send"
                    onClick={() => { file ? sendFile() : sendMessage() }}
                    style={{ cursor: 'pointer' }}
                />
            </div>
            <div style={{ width: "100%", margin: "auto", marginTop: "20px", color: "silver" }}><hr /></div>
        </>
    );
}

const MessageBubble = ({ msg, currentID, getFileUrl }) => {
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        const fetchFileUrl = async () => {
            if (msg.file) {
                const url = await getFileUrl(msg.file);
                setFileUrl(url);
                console.log(`File URL for message ${msg._id}: ${url}`);
            }
        };

        fetchFileUrl();
    }, [msg.file, getFileUrl]);

    const renderMessageContent = () => {
        if (msg.file) {
            const fileExtension = msg.file.split('.').pop().toLowerCase();

            if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
                return (
                    <div style={{ position: 'relative', width: '100%', maxWidth: '300px' }}>
                        {/* <video controls style={{ width: '100%', borderRadius: '12px' }}>
                            <source src={fileUrl} type={`video/${fileExtension}`} />
                            Your browser does not support the video tag.
                        </video>
                        <div style={{ position: 'absolute', bottom: '8px', right: '8px', color: 'white', fontSize: '12px' }}>
                            {Math.round(msg.fileSize / 1024)} KB
                        </div> */}
                        <iframe allowFullScreen src={fileUrl} style={{width:"100%"}}>

                        </iframe>
                    </div>
                );
            } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
                return (
                    <div style={{ position: 'relative', width: '100%', maxWidth: '200px' }}>
                        <img src={fileUrl} alt="Image" style={{ width: '100%', borderRadius: '12px' }} />
                        <div style={{ position: 'absolute', bottom: '8px', right: '8px', color: 'white', fontSize: '12px' }}>
                            {Math.round(msg.fileSize / 1024)} KB
                        </div>
                    </div>
                );
            } else if (fileExtension === 'pdf') {
                return (
                    <div style={{ width: '100%', maxWidth: '600px', border: '1px solid #ddd', borderRadius: '12px', overflow: 'hidden' }}>
                        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                            <Viewer fileUrl={fileUrl} />
                        </Worker>
                    </div>
                );
            } else {
                return (
                    <a href={fileUrl} download>
                        Download {msg.file.split('.').pop().toUpperCase()} File
                    </a>
                );
            }
        } else if (msg.voiceNote) {
            return (
                <audio controls>
                    <source src={getFileUrl(msg.voiceNote)} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
            );
        } else if (msg.message) {
            return <p className="chat_p3">{msg.message}</p>;
        } else {
            return null;
        }
    };

    return (
        msg.user && msg.user._id === currentID ? (
            <div className="own-message-container">
                {renderMessageContent()}
                <p className="own-message-time">{new Date(msg.timestamp).toLocaleTimeString()}</p>
            </div>
        ) : (
            <div className="reciver-container">
                <div className="chat_div">
                    <img className="chat_nav_img2" src={happy} alt="" />
                    {renderMessageContent()}
                </div>
                <div className="reciver-time">
                    <p>{new Date(msg.timestamp).toLocaleTimeString()}</p>
                </div>
            </div>
        )
    );
};

export default Chat;
