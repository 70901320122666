import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route,  Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { baseTheme } from './assets/global/Theme-variable';
import FullLayout from "./layouts/FullLayout/FullLayout";
import Chat from "./views/Chat";
import CreateProfile from "./views/CreateProfile";
import Dashboard1 from "./views/dashboards/Dashboard1";
import LoginSignup from "./views/Login";
import NewsMain from "./views/NewsMain";
import Users from "./views/Users";
import VideoExplore from "./views/VideoExplore";

const App = () => {
  const theme = baseTheme;
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginSignup />} />
          <Route
            path="/app"
            element={<FullLayout />}
          >
            <Route path="dashboard1" element={<Dashboard1 />} />
            <Route path="Users" element={<Users />} />
            <Route path="VideoExplore" element={<VideoExplore />} />
            <Route path="News" element={<NewsMain />} />
            <Route path="ChatRoom" element={<Chat />} />
            <Route path="CreateProfile" element={<CreateProfile />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
