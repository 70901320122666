import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ChatIcon from '@mui/icons-material/Chat';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
const Menuitems = [
  {
    title: "داشبورد",
    icon: DashboardOutlinedIcon,
    href: "/app/dashboard1",
  },
  {
    title: "کاربران",
    icon: AutoAwesomeMosaicOutlinedIcon,
    href: "/app/Users",
  },
  {
    title: "اکتشاف ویدیو",
    icon: OndemandVideoIcon,
    href: "/app/VideoExplore",
  },
  {
    title: "اخبار",
    icon: NewspaperIcon,
    href: "/app/News",
  },
  {
    title: "چت روم",
    icon: ChatIcon,
    href: "/app/ChatRoom",
  },
  {
    title: "ایجاد پروفایل",
    icon: PersonAddAltIcon,
    href: "/app/CreateProfile",
  },
];

export default Menuitems;
