import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import { imageBackendLink } from "../../../env";

// const products = [
//   {
//     id: "1",
//     name: "Sunil Joshi",
//     post: "Web Designer",
//     pname: "Elite Admin",
//     priority: "Low",
//     pbg: "primary.main",
//     budget: "3.9",
//   },
//   {
//     id: "2",
//     name: "Andrew McDownland",
//     post: "Project Manager",
//     pname: "Real Homes WP Theme",
//     priority: "Medium",
//     pbg: "secondary.main",
//     budget: "24.5",
//   },
//   {
//     id: "3",
//     name: "Christopher Jamil",
//     post: "Project Manager",
//     pname: "MedicalPro WP Theme",
//     priority: "High",
//     pbg: "error.main",
//     budget: "12.8",
//   },
//   {
//     id: "4",
//     name: "Nirav Joshi",
//     post: "Frontend Engineer",
//     pname: "Hosting Press HTML",
//     priority: "Critical",
//     pbg: "success.main",
//     budget: "2.4",
//   },
// ];

const ExTable = ({ products }) => {
  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h5">
            تصویر پروفایل
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h5">
            نام کامل
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h5">
            شماره کارت شناسایی
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h5">
            جنس
            </Typography>
          </TableCell>
          <TableCell >
            <Typography color="textSecondary" variant="h5">
            شماره تلفن
            </Typography>
          </TableCell>
          <TableCell >
            <Typography color="textSecondary" variant="h5">
            آدرس ایمیل
            </Typography>
          </TableCell>
          <TableCell >
            <Typography color="textSecondary" variant="h5">
            آدرس خانه
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products?.map((product) => (
          <TableRow key={product.name}>
            <TableCell>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                <img src={`${imageBackendLink}${product.personalInfomation?.profilePhoto}`} width={40} style={{ borderRadius: "5px" }} />
              </Typography>
            </TableCell>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "600",
                    }}
                  >
                    {product.personalInfomation?.fullName}
                  </Typography>

                </Box>
              </Box>
            </TableCell>
            <TableCell>
              <Typography color="textSecondary" variant="h6">
                {product.personalInfomation?.idCardNumber}
              </Typography>
            </TableCell>
            <TableCell>
              <Chip
                sx={{
                  pl: "4px",
                  pr: "4px",
                  backgroundColor: product.personalInfomation?.gender === "Male" ? "secondary.main" : "success.main",
                  color: "#fff",
                }}
                size="small"
                label={product.personalInfomation?.gender}
              ></Chip>
            </TableCell>
            <TableCell >
              <Typography variant="h6">{product.contactInformation.phoneNumberMobile}</Typography>
            </TableCell>
            <TableCell >
              <Typography variant="h6">{product.contactInformation.emailAddress}</Typography>
            </TableCell>
            <TableCell >
              <Typography variant="h6">{product.contactInformation.fullHomeAddress}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ExTable;
