import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { backendLink } from '../../env';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

const LoginSignup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [active, setActive] = useState(false);

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        if (!email || !password) {
            toast.error('Please Fill Input');
        } else {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');

            const raw = JSON.stringify({
                email: email,
                password: password,
            });

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            try {
                const response = await fetch(
                    `${backendLink}/Authentication/Login`,
                    requestOptions
                );
                const result = await response.json();

                setLoading(false);

                if (result.status === 400) {
                    toast.error(result.message);
                } else {
                    await localStorage.setItem('userData', JSON.stringify(result));
                    setEmail('');
                    setPassword('');
                    navigate('/app/dashboard1');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred. Please try again.');
                setLoading(false);
            }
        }
    };


    const handleSignupSubmit = (e) => {
        e.preventDefault();
        // Perform signup logic here
        navigate('/app/dashboard1');
    };

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100vh" }}>
            <section className={`wrapper ${active ? 'active' : ''}`}>
                <div className="form signup">
                    <header onClick={() => setActive(false)}>ثبت نام</header>
                    <form onSubmit={handleSignupSubmit}>
                        <input type="text" placeholder="نام کامل" required />
                        <input type="text" placeholder="آدرس ایمیل" required />
                        <input type="text" placeholder="شماره تلفن" required />
                        <input type="password" placeholder="کلمه عبور" required />
                        <input type="submit" value="ثبت نام" />
                    </form>
                </div>

                <div className="form login">
                    <header onClick={() => setActive(true)}>ورود به سیستم</header>
                    <form onSubmit={handleLoginSubmit}>
                        <input type="text" placeholder="آدرس ایمیل" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input type="password" placeholder="کلمه عبور" required value={password} onChange={(e) => setPassword(e.target.value)} />
                        {loading ? <div className='login-loader'><CircularProgress size={20} style={{ color: "#fff" }} /></div> : <input type="submit" value="ورود به سیستم" />}
                    </form>
                </div>
            </section>
        </div>
    );
};

export default LoginSignup;
