import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Box, Card, CardContent, CircularProgress, Divider, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { News } from '../dashboards/dashboard1-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const inputSX = {
    width: '100%',
    marginTop: 10,
    // border: '1px solid #303740',
    padding: 10,
    borderRadius: 8,
    display: 'none' // Hide the input
};

// upload button style
const uploadButtonSX = {
    marginTop: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: 15,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer'
};
export default function NewsMain() {
    const [getAllData, setGetAllData] = useState([])
    const [imageFile, setImageFile] = useState("");
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [loader, setLoader] = useState(false);
    const imageInputRef = useRef(null);
    const navigate = useNavigate()
    const handleImageChange = (event) => {
        if (event.target.files.length > 0) {
            setImageFile(event.target.files[0]);
        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleUploadClick = () => {
        document.getElementById('image-upload-input').click();
    };
    const FetchAllData = async () => {
        try {
            // setLoader(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                // body: raw,
                redirect: "follow"
            };
            // const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Add',
            const response = await fetch('https://villageconnect.codexsmart.com/api/v1/News/Get',
                requestOptions
            );

            const data = await response.json();

            if (response.ok) {
                setLoader(false)
                // toast.success(data.message);
                console.log(data);
                setGetAllData(data.news)
            } else {
                setLoader(false)
                toast.error('Error: ' + (data.message || 'Something went wrong'));
            }
        } catch (error) {
            setLoader(false)
            toast.error('Error uploading video. Please try again.');
        }
    }
    useEffect(() => {
        FetchAllData()
    }, [])
    const handleSubmit = async () => {
        if (!imageFile || !title || !description) {
            toast.error('Please select an image file and provide a title.');
            return;
        }
        try {
            setLoader(true)
            const formData = new FormData();
            formData.append("image", imageFile);
            formData.append("title", title);
            formData.append("description", description);

            const requestOptions = {
                method: "POST",
                body: formData,
                redirect: "follow"
            };
            const response = await fetch('https://villageconnect.codexsmart.com/api/v1/News/Add', requestOptions);

            const data = await response.json();

            if (response.ok) {
                setLoader(false)
                toast.success("SuccessFully News Add");
                FetchAllData()
                // Clear input fields and state after successful upload
                setImageFile("");
                setTitle('');
                setDescription('')
            } else {
                setLoader(false)
                toast.error('Error: ' + (data.message || 'Something went wrong'));
            }
        } catch (error) {
            setLoader(false)
            toast.error('Error uploading image. Please try again.');
        }
    };
    console.log(description);

    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                            }}
                        >
                            اخبار
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={5.9} style={{ marginRight: "10px" }}>
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}>عنوان</Typography>
                            <TextField placeholder='عنوان'
                                value={title}
                                onChange={handleTitleChange}
                                style={{ width: "100%", marginTop: "10px" }} />
                            {/* <TextField placeholder='Thumbnail'
                                value={thumbnail}
                                onChange={handleThumbnailChange} style={{ width: "100%", marginTop: "10px" }} /> */}
                            {/* <input
                            placeholder='Thumbnail'
                            value={thumbnail}
                            onChange={handleThumbnailChange}
                            style={{ fontFamily: "Poppins", fontSize: 14, fontWeight: "500", width: "100%", marginTop: 10, border: "1px solid #303740", padding: 10, borderRadius: 8, background: "transparent" }}
                        /> */}
                        </Grid>
                        <Grid item xs={12} md={5.9}>
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}>تصویر</Typography>
                            <div style={uploadButtonSX} onClick={handleUploadClick}>
                                <AttachFileIcon sx={{ marginRight: 1 }} />
                                <Typography color="textSecondary"
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        // mt: 1,
                                        // padding: "10px"
                                    }}>{imageFile ? imageFile.name : 'یک فایل تصویری را انتخاب کنید'}</Typography>
                            </div>
                            <TextField
                                id="image-upload-input"
                                type="file"
                                accept="image/*"
                                style={inputSX}
                                onChange={handleImageChange}
                                ref={imageInputRef}

                            />
                        </Grid>
                        <Grid item xs={12} md={12} style={{ marginTop: "15px" }}>
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginBottom: '10px'
                                }}>توضیح</Typography>
                            <ReactQuill theme="snow" style={{ borderRadius: "12px" }} value={description} onChange={setDescription} />;
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>{loader ? <CircularProgress size={20} style={{ color: "#fff" }} /> : "آپلود کنید"}</Button>
                    </Grid>
                    {uploadStatus && (
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">
                                {uploadStatus}
                            </Typography>
                        </Grid>
                    )}
                    <News />
                </CardContent>
            </Card>

        </>
    );
}
