import { Box, Card, CardContent, CircularProgress, Divider, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import YouTube from 'react-youtube';

export default function VideoExplore() {
    const [getAllData, setGetAllData] = useState([])
    const [videoFile, setVideoFile] = useState("");
    const [thumbnail, setThumbnail] = useState('');
    const [uploadStatus, setUploadStatus] = useState('');
    const [loader, setLoader] = useState(false);

    const videoInputRef = useRef(null);
    const navigate = useNavigate()

    const FetchAllData = async () => {
        try {
            // setLoader(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                // body: raw,
                redirect: "follow"
            };
            // const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Add',
            const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Get',
                requestOptions
            );

            const data = await response.json();

            if (response.ok) {
                setLoader(false)
                // toast.success(data.message);
                console.log(data);
                setGetAllData(data.VideoUpload)
            } else {
                setLoader(false)
                toast.error('Error: ' + (data.message || 'Something went wrong'));
            }
        } catch (error) {
            setLoader(false)
            toast.error('Error uploading video. Please try again.');
        }
    }
    useEffect(() => {
        FetchAllData()
    }, [])
    const handleVideoChange = (event) => {
        if (event.target.files.length > 0) {
            setVideoFile(event.target.files[0]);
        }
    };

    const handleThumbnailChange = (event) => {
        setThumbnail(event.target.value);
    };

    const handleUploadClick = () => {
        document.getElementById('video-upload-input').click();
    };

    const handleSubmit = async () => {
        if (!videoFile || !thumbnail) {
            toast.error('Please select a video file and provide a thumbnail.');
            return;
        }
        try {
            setLoader(true)
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "video": videoFile,
                "thumbnail": thumbnail
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };
            // const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Add',
            const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Add',
                requestOptions
            );

            const data = await response.json();

            if (response.ok) {
                setLoader(false)
                toast.success(data.message);
                // Clear input fields and state after successful upload
                setVideoFile("");
                setThumbnail('');
                // videoInputRef.current.value = null;
            } else {
                setLoader(false)
                toast.error('Error: ' + (data.message || 'Something went wrong'));
            }
        } catch (error) {
            setLoader(false)
            toast.error('Error uploading video. Please try again.');
        }
    };
    const getYouTubeVideoId = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        // console.log(match);
        return match ? match[1] : null;
    };
    const videoOpts = {
        height: '350',
        width: '311',
        borderRadius: "10"
    };
    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    p: 0,
                }}
            >
                <Box
                    sx={{
                        padding: "15px 30px",
                    }}
                    display="flex"
                    alignItems="center"
                >
                    <Box flexGrow={1}>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "500",
                            }}
                        >
                            اکتشاف ویدیو
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        padding: "30px",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={5.9} style={{ marginRight: "10px" }}>
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}>تصویر کوچک</Typography>
                            <TextField placeholder='تصویر کوچک'
                                value={thumbnail}
                                onChange={handleThumbnailChange} style={{ width: "100%", marginTop: "10px" }} />
                            {/* <input
                            placeholder='Thumbnail'
                            value={thumbnail}
                            onChange={handleThumbnailChange}
                            style={{ fontFamily: "Poppins", fontSize: 14, fontWeight: "500", width: "100%", marginTop: 10, border: "1px solid #303740", padding: 10, borderRadius: 8, background: "transparent" }}
                        /> */}
                        </Grid>
                        <Grid item xs={12} md={5.9}>
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}>لینک ویدیو</Typography>
                            <TextField
                                placeholder='لینک ویدیو'
                                value={videoFile}
                                onChange={(e) => setVideoFile(e.target.value)}
                                style={{ width: "100%", marginTop: "10px" }}
                            // style={{ fontFamily: "Poppins", fontSize: 14, fontWeight: "500", width: "100%", marginTop: 10, border: "1px solid #303740", padding: 10, borderRadius: 8, background: "transparent" }}
                            />
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={10} sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>{loader ? <CircularProgress size={20} style={{ color: "#fff" }} /> : "آپلود کنید"}</Button>
                    </Grid>
                    {uploadStatus && (
                        <Grid item xs={12}>
                            <Typography variant="body1" color="error">
                                {uploadStatus}
                            </Typography>
                        </Grid>
                    )}

                </CardContent>
            </Card>
            <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between", margin: "0 auto" }}>
                {getAllData?.map((e, i) => {
                    const videoId = getYouTubeVideoId(e.video);
                    return (

                        <div style={{ boxShadow: "2px 2px 5px 0px rgba(0,0,0,0.75)", width: "30%", marginBottom: "15px", borderRadius: 10 }}>
                            <YouTube videoId={`${videoId}`} opts={videoOpts} style={{ width: "10px" }} />
                            <Typography color="textSecondary"
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    mt: 1,
                                    padding: "10px"
                                }}>{e.thumbnail}</Typography>
                        </div>
                    )
                })}
            </div>
        </>
    );
}
