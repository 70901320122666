import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";
import ExTable from "../dashboards/dashboard1-components/ExTable";
import { backendLink } from "../../env";
import Spinner from "../Spinner/Spinner";

const Users = () => {

  const [userInfo, setUserInfo] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`${backendLink}/Info/Get`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setLoader(false)
        if (result.status === 200) {
          setUserInfo(result.userInfo);
        }
      })
      .catch((error) => {
        setLoader(false)
        console.error(error)
      });
  }, []);

  return (
    <Box>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h3">کاربران</Typography>
          <Box
            sx={{
              overflow: {
                xs: "auto",
                sm: "unset",
              },
            }}
          >
            {loader ? <Spinner /> : <ExTable products={userInfo} />}
            
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Users;
