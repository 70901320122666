import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import Spinner from "../Spinner/Spinner.js";
import {
  News,
  SalesOverview,
  ProductPerformance,
  DailyActivities,
} from "./dashboard1-components";
import ChartComponent from "../../components/Chart";
import { backendLink } from "../../env";

const Dashboard1 = () => {
  const [userInfo, setUserInfo] = useState([]);
  const [loader, setLoader] = useState(true);
  const [jobTypeData, setJobTypeData] = useState({ government: 0, private: 0, freelance: 0, unemployed: 0 });
  const [employmentStatusData, setEmploymentStatusData] = useState({ employed: 0, entrepreneur: 0, unemployed: 0 });
  const [mariedStatusData, setMariedStatusData] = useState({ single: 0, married: 0, widowed: 0, divorced: 0 });
  const [ageData, setAgeData] = useState({ 18: 0, 40: 0, 100: 0 });
  const [criminalData, setCriminalData] = useState({ yes: 0, no: 0 });
  const [genderData, setGenderData] = useState({ male: 0, female: 0 });

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${backendLink}/Info/Get`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setLoader(false);
        if (result.status === 200) {
          setUserInfo(result.userInfo);
          // Process job type data
          const jobTypeCounts = result.userInfo.reduce(
            (acc, user) => {
              if (user.economicInformation.typeOfJob === "Government") {
                acc.government += 1;
              } else if (user.economicInformation.typeOfJob === "Private") {
                acc.private += 1;
              } else if (user.economicInformation.typeOfJob === "Freelance") {
                acc.Freelance += 1;
              } else if (user.economicInformation.typeOfJob === "Unemployed") {
                acc.unemployed += 1;
              }
              return acc;
            },
            { government: 0, private: 0, freelance: 0, unemployed: 0 }
          );
          setJobTypeData(jobTypeCounts);
          const employmentStatusCounts = result.userInfo.reduce(
            (acc, user) => {
              if (user.economicInformation.employmentStatus === "Employed") {
                acc.employed += 1;
              } else if (user.economicInformation.employmentStatus === "Entrepreneur") {
                acc.entrepreneur += 1;
              } else if (user.economicInformation.employmentStatus === "Unemployed") {
                acc.unemployed += 1;
              }
              return acc;
            },
            { employed: 0, entrepreneur: 0, unemployed: 0 }
          );
          setEmploymentStatusData(employmentStatusCounts);
          const mariedStatusCounts = result.userInfo.reduce(
            (acc, user) => {
              if (user.personalInfomation.maritalStatus === "Single") {
                acc.single += 1;
              } else if (user.personalInfomation.maritalStatus === "Married") {
                acc.married += 1;
              } else if (user.personalInfomation.maritalStatus === "Widowed") {
                acc.widowed += 1;
              } else if (user.personalInfomation.maritalStatus === "Divorced") {
                acc.divorced += 1;
              }
              return acc;
            },
            { single: 0, married: 0, widowed: 0, divorced: 0 }
          );
          setMariedStatusData(mariedStatusCounts);
          // console.log(result);

          const AgeCounts = result.userInfo.reduce(
            (acc, user) => {
              const age = user.familyInformation.age;
              // console.log(age);
              if (age <= 18) {
                acc[18] += 1; // Increment the count for the 18 category
              } else if (age > 18 && age <= 40) {
                acc[40] += 1; // Increment the count for the 40 category
              } else if (age > 40) {
                acc[100] += 1; // Increment the count for the 100 category
              }

              return acc;
            },
            { 18: 0, 40: 0, 100: 0 } // Initial values for each age category
          );

          setAgeData(AgeCounts);
          const CriminalCount = result.userInfo.reduce(
            (acc, user) => {
              const criminal = user.securityInformation.hasCriminalRecord;
              // console.log(criminal);
              if (user.securityInformation.hasCriminalRecord === "Yes") {
                acc.yes += 1; // Increment the count for the 18 category
              } else if (user.securityInformation.hasCriminalRecord === "No") {
                acc.no += 1; // Increment the count for the 40 category
              }

              return acc;
            },
            { yes: 0, no: 0 } // Initial values for each age category
          );

          setCriminalData(CriminalCount);
          const GenderCount = result.userInfo.reduce(
            (acc, user) => {
              const Gender = user.personalInfomation?.gender;
              // console.log(criminal);
              if (Gender === "Male") {
                acc.male += 1; // Increment the count for the 18 category
              } else if (Gender === "Female") {
                acc.female += 1; // Increment the count for the 40 category
              }

              return acc;
            },
            { male: 0, female: 0 } // Initial values for each age category
          );

          setGenderData(GenderCount);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
      });
  }, []);
  // console.log(ageData);

  return loader ? (
    <Spinner />
  ) : (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={jobTypeData} data={[
            {
              label: "حکومت",
              value: jobTypeData.government,
              color: "rgba(60, 179, 113, 1)",
            },
            {
              label: "خصوصی",
              value: jobTypeData.private,
              color: "rgba(54, 162, 235, 1)",
            },
            {
              label: "فریلنسر",
              value: jobTypeData.freelance,
              color: "rgba(75, 192, 192, 1)",
            },
            {
              label: "بیکار",
              value: jobTypeData.unemployed,
              color: "rgba(255, 0, 0, 1)",
            },
          ]} heading={"نوع وظیفه توزیع"} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={employmentStatusData} data={[
            {
              label: "استخدام شده",
              value: employmentStatusData.employed,
              color: "rgba(232, 96, 41, 1)",
            },
            {
              label: "تجارت پیشه",
              value: employmentStatusData.entrepreneur,
              color: "rgba(54, 162, 235, 1)",
            },

            {
              label: "بیکار",
              value: employmentStatusData.unemployed,
              color: "rgba(255, 0, 0, 1)",
            },
          ]} heading={"وضعیت اشتغال"} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={mariedStatusData} data={[
            {
              label: "مجرد",
              value: mariedStatusData.single,
              color: "rgba(232, 96, 41, 1)",
            },
            {
              label: "متاهل",
              value: mariedStatusData.married,
              color: "rgba(54, 162, 235, 1)",
            },
            {
              label: "بیوه",
              value: mariedStatusData.widowed,
              color: "rgba(153, 102, 255, 1)",
            },
            {
              label: "طلاق گرفته",
              value: mariedStatusData.divorced,
              color: "rgba(75, 192, 192, 1)",
            },
          ]} heading={"وضعیت ازدواج"} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={criminalData} data={[
            {
              label: "بله",
              value: criminalData.yes,
              color: "rgba(232, 96, 41, 1)",
            },
            {
              label: "نه",
              value: criminalData.no,
              color: "rgba(54, 162, 235, 1)",
            },
          ]} heading={"وضعیت جنایی"} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={ageData} data={[
            {
              label: "18",
              value: ageData[18],
              color: "rgba(232, 96, 41, 1)",
            },
            {
              label: "40",
              value: ageData[40],
              color: "rgba(54, 162, 235, 1)",
            },
            {
              label: "100",
              value: ageData[100],
              color: "rgba(153, 102, 255, 1)",
            },
          ]} heading={"وضعیت سنی"} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <ChartComponent jobTypeData={genderData} data={[
            {
              label: "مذکر",
              value: genderData.male,
              color: "rgba(232, 96, 41, 1)",
            },
            {
              label: "مونث",
              value: genderData.female,
              color: "rgba(54, 162, 235, 1)",
            },
          ]} heading={"جنس"} />
        </Grid>
        {/* ------------------------- row 1 ------------------------- */}
        {/* <Grid item xs={12} lg={12}>
          <SalesOverview />
        </Grid> */}
        {/* ------------------------- row 2 ------------------------- */}
        {/* <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid> */}
        {/* ------------------------- row 3 ------------------------- */}
        <News />
      </Grid>
    </Box>
  );
};

export default Dashboard1;
