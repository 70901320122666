import React, { useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Button, Card, CardContent, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Next from "../../assets/images/next.png"
import Previous from "../../assets/images/previous.png"
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const inputSX = {
    width: '100%',
    marginTop: 10,
    // border: '1px solid #303740',
    padding: 10,
    borderRadius: 8,
    display: 'none' // Hide the input
};

// upload button style
const uploadButtonSX = {
    marginTop: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: 15,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer'
};
function CreateProfile() {
    const imageInputRef = useRef(null);
    const [steps, setSteps] = useState(0);
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs());
    const [marital, setMarital] = useState("");
    const [selected, setSelected] = useState("");
    const [profileImage, setProfileImage] = useState(null);
    const [familyEducation, setFamilyEducatiion] = useState("");
    const [employmentStatus, setEmploymentStatus] = useState("");
    const [relationship, setRelationship] = useState("");
    const [ecoEmploymentStatus, setEcoEmploymentStatus] = useState("");
    const [typeJob, setTypeJob] = useState("");
    const [educationLevel, setEducationLevel] = useState("");
    const [educationDegree, setEducationDegree] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [ownershipStatus, setOwnershipStatus] = useState("");
    const [propertyUsage, setPropertyUsage] = useState("");
    const [healthStatus, setHealthStatus] = useState("");
    const [healthService, setHealthService] = useState("");
    const [healthInsurance, setHealthInsurance] = useState("");
    const [socialStatus, setSocialStatus] = useState("");
    const [criminal, setCriminal] = useState("");
    const [accessInternet, setAccessInternet] = useState("");
    const [vehicles, setVehicles] = useState("");
    const [loading, setLoading] = useState(false);
    const [personalInfo, setPersonalInfo] = useState({
        fullName: '',
        fathersName: '',
        ethnicity: '',
        idCard: '',
        MaintenanceHandBook: '',
        bloodDonate: ''
    });
    const [contactInfo, setContactInfo] = useState({
        phoneLandline: '',
        phoneMobile: '',
        email: '',
        homeAddress: '',
    });

    const [familyInfo, setFamilyInfo] = useState({
        FamilyMemeber: '',
        Ages: '',
    });
    const [ecoInfo, setEcoInfo] = useState({
        monthlyIncome: '',
        nameCompany: '',
        workExprience: '',
    });
    const [educationInfo, setEducationInfo] = useState({
        fieldStudy: '',
        typeLocationStudy: '',
        skills: '',
    });
    const [propertyInfo, setPropertyInfo] = useState({
        area: '',
    });
    const [socialInfo, setSocialInfo] = useState({
        memberShipGroup: '',
        charityActivity: '',
    });
    const [securityInfo, setSecurityInfo] = useState({
        name: '', relationship: "", phoneNumber: "",
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        plateNumber: '', hobby: ""
    });
    const handlePersonalInfoChange = (key, value) => {
        setPersonalInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleContactInfoChange = (key, value) => {
        setContactInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleFamilyInfoChange = (key, value) => {
        setFamilyInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleEcoInfoChange = (key, value) => {
        setEcoInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleEducationInfoChange = (key, value) => {
        setEducationInfo(prev => ({ ...prev, [key]: value }));
    };
    const handlePropertyInfoChange = (key, value) => {
        setPropertyInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleSocialInfoChange = (key, value) => {
        setSocialInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleSecurityInfoChange = (key, value) => {
        setSecurityInfo(prev => ({ ...prev, [key]: value }));
    };
    const handleAdditionalInfoChange = (key, value) => {
        setAdditionalInfo(prev => ({ ...prev, [key]: value }));
    };
    const isPersonalInfoComplete = () => {
        return Object.values(personalInfo).every(val => val.trim() !== '') && selected !== "" && marital !== "" && selectedStartDate !== "";
    };
    const isContactInfoComplete = () => {
        return Object.values(contactInfo).every(val => val.trim() !== '');
    };
    const isFamilyInfoComplete = () => {
        return Object.values(familyInfo).every(val => val.trim() !== '') && familyEducation !== "" && employmentStatus !== "" && relationship !== "";
    };
    const isEcoInfoComplete = () => {
        return Object.values(ecoInfo).every(val => val.trim() !== '') && typeJob !== "" && ecoEmploymentStatus !== "";
    };
    const isEducationInfoComplete = () => {
        return Object.values(educationInfo).every(val => val.trim() !== '') && educationLevel !== "" && educationDegree !== "";
    };
    const isPropertyInfoComplete = () => {
        return Object.values(propertyInfo).every(val => val.trim() !== '') && propertyType !== "" && propertyUsage !== "" && ownershipStatus !== "";
    };
    const isHealthInfoComplete = () => {
        return healthStatus !== "" && healthService !== "" && healthInsurance !== "";
    };
    const isSocialInfoComplete = () => {
        return Object.values(socialInfo).every(val => val.trim() !== '') && socialStatus !== "";
    };
    const isEmergencyInfoComplete = () => {
        return Object.values(securityInfo).every(val => val.trim() !== '') && criminal !== "";
    }
    const isAdditionalInfoComplete = () => {
        return Object.values(additionalInfo).every(val => val.trim() !== '') && accessInternet !== "" && vehicles !== "";
    };
    const handleUploadClick = () => {
        document.getElementById('image-upload-input').click();
    };

    const handleImageChange = (event) => {
        if (event.target.files.length > 0) {
            setProfileImage(event.target.files[0]);
        }
    };

    const nextStep = () => {
        setSteps(steps + 1);
    };

    const PreviousStep = () => {
        setSteps(steps - 1);
    };
    const startDate = selectedStartDate ? selectedStartDate.toString() : "";
    console.log(startDate);
    const InfoDone = () => {
        setLoading(true);

        const formData = new FormData();

        // Append profile image file
        if (profileImage) {
            formData.append('profile', {
                uri: profileImage,
                type: 'image/jpeg', // Replace with appropriate type
                name: 'profile.jpg', // Replace with appropriate name
            });
        }

        // Append other data
        formData.append('personalInfomation', JSON.stringify({
            fullName: personalInfo.fullName,
            fatherName: personalInfo.fathersName,
            dateOfBirth: startDate,
            gender: selected,
            ethnicity: personalInfo.ethnicity,
            maritalStatus: marital,
            idCardNumber: personalInfo.idCard,
            MaintenanceHandBook: personalInfo.MaintenanceHandBook,
            bloodDonate: personalInfo.bloodDonate
        }));

        formData.append('contactInformation', JSON.stringify({
            phoneNumberLandline: contactInfo.phoneLandline,
            phoneNumberMobile: contactInfo.phoneMobile,
            emailAddress: contactInfo.email,
            fullHomeAddress: contactInfo.homeAddress,
        }));

        formData.append('familyInformation', JSON.stringify({
            numberOfFamilyMembers: familyInfo.FamilyMemeber,
            age: familyInfo.Ages,
            educationalStatus: familyEducation,
            employmentStatus: employmentStatus,
            relationship: relationship
        }));

        formData.append('economicInformation', JSON.stringify({
            jobMonthlyIncome: ecoInfo.monthlyIncome,
            typeOfJob: typeJob,
            employer: ecoInfo.nameCompany,
            employmentStatus: ecoEmploymentStatus,
            workExperience: ecoInfo.workExprience
        }));

        formData.append('educationalInformation', JSON.stringify({
            educationLevel: educationLevel,
            fieldOfStudy: educationInfo.fieldStudy,
            typeAndLocationOfEducation: educationInfo.typeLocationStudy,
            educationalDegrees: educationDegree,
            specializationsAndSkills: educationInfo.skills
        }));

        formData.append('propertyInformation', JSON.stringify({
            typeOfProperty: propertyType,
            ownershipStatus: ownershipStatus,
            propertyArea: propertyInfo.area,
            propertyUsage: propertyUsage
        }));

        formData.append('healthInformation', JSON.stringify({
            healthStatus: healthStatus,
            accessToHealthServices: healthService,
            hasInsurance: healthInsurance,
        }));

        formData.append('socialInformation', JSON.stringify({
            socialStatus: socialStatus,
            memberships: socialInfo.memberShipGroup,
            voluntaryActivities: socialInfo.charityActivity,
        }));

        formData.append('securityInformation', JSON.stringify({
            hasCriminalRecord: criminal,
            name: securityInfo.name,
            relationship: securityInfo.relationship,
            phoneNumber: securityInfo.phoneNumber
        }));

        formData.append('additionalInformation', JSON.stringify({
            hasInternetAccess: accessInternet,
            vehicles: vehicles,
            licensePlateNumber: additionalInfo.plateNumber,
            hobbiesAndInterests: additionalInfo.hobby
        }));

        const requestOptions = {
            method: "POST",
            body: formData,
            headers: {
                // 'Content-Type': 'multipart/form-data', // Ensure this is correct
            }
        };

        fetch("https://villageconnect.codexsmart.com/api/v1/Info/Add", requestOptions)
            // fetch("http://192.168.100.202:4000/api/v1/Info/Add", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.status === 400) {
                    toast.error(result.message);
                } else {
                    console.log(result, "done");
                    // Reset form states...
                    // Resetting form data and states
                    setPersonalInfo({
                        fullName: '',
                        fathersName: '',
                        ethnicity: '',
                        idCard: '',
                        bloodDonate: ""
                    });

                    setContactInfo({
                        phoneLandline: '',
                        phoneMobile: '',
                        email: '',
                        homeAddress: '',
                    });
                    setFamilyInfo({
                        FamilyMemeber: '',
                        Ages: '',
                    });
                    setEducationInfo({
                        fieldStudy: '',
                        typeLocationStudy: '',
                        skills: '',
                    });
                    setEcoInfo({
                        monthlyIncome: '',
                        nameCompany: '',
                        workExprience: '',
                    });
                    setPropertyInfo({
                        area: '',
                    });
                    setSocialInfo({
                        memberShipGroup: '',
                        charityActivity: '',
                    });
                    setSecurityInfo({
                        name: '', relationship: "", phoneNumber: "",
                    });
                    setAdditionalInfo({
                        plateNumber: '', hobby: ""
                    });

                    setSelected("");
                    setMarital("");
                    setFamilyEducatiion("");
                    setEmploymentStatus("");
                    setEcoEmploymentStatus("");
                    setRelationship("");
                    setTypeJob("")
                    setEducationLevel("");
                    setEducationDegree("");
                    setPropertyType("");
                    setOwnershipStatus("");
                    setPropertyUsage("");
                    setHealthStatus("");
                    setHealthService("");
                    setHealthInsurance("");
                    setSocialStatus("");
                    setCriminal("");
                    setAccessInternet("");
                    setVehicles("");
                    setProfileImage(null);
                    toast('Successfully Added Person');
                    setSteps(0)
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error('An error occurred. Please try again.');
                console.log(error)
            });
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <h1 style={{ textAlign: 'center' }}>پروفایل ایجاد کنید</h1>
                <Box>
                    <Card variant="outlined">
                        {steps === 0 ?
                            (<CardContent>
                                <Typography variant="h3">معلومات شخصی</Typography>
                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>نام کامل</Typography>
                                        <TextField placeholder='نام کامل'
                                            value={personalInfo.fullName}
                                            onChange={(e) => handlePersonalInfoChange('fullName', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>نام پدر</Typography>
                                        <TextField placeholder='نام پدر'
                                            value={personalInfo.fathersName}
                                            onChange={(e) => handlePersonalInfoChange('fathersName', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>تاریخ تولد</Typography>
                                        {/* <StaticDatePicker
                                            displayStaticWrapperAs="desktop"
                                            value={selectedStartDate}
                                            onChange={(newValue) => {
                                                setSelectedStartDate(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} style={{ width: '100%', marginTop: '10px' }} />}
                                        /> */}
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            {/* <DemoContainer components={['DatePicker']}> */}
                                            <DatePicker sx={{ width: "100%", marginTop: "10px" }} value={selectedStartDate} onChange={(e) => selectedStartDate(e)} />
                                            {/* </DemoContainer> */}
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>قومیت</Typography>
                                        <TextField placeholder='قومیت' value={personalInfo.ethnicity}
                                            onChange={(e) => handlePersonalInfoChange('ethnicity', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>وضعیت ازدواج</Typography>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                            <Select
                                                // labelId="demo-simple-select-label"
                                                // id="demo-simple-select"
                                                value={marital}
                                                // label="Marital"
                                                onChange={(e) => setMarital(e.target.value)}
                                            >
                                                <MenuItem value={"مجرد"}>مجرد</MenuItem>
                                                <MenuItem value={"متاهل"}>متاهل</MenuItem>
                                                <MenuItem value={"بیوه"}>بیوه</MenuItem>
                                                <MenuItem value={"طلاق گرفته"}>طلاق گرفته</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>جنس</Typography>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                            <Select
                                                // labelId="demo-simple-select-label"
                                                // id="demo-simple-select"
                                                value={selected}
                                                // label="Marital"
                                                onChange={(e) => setSelected(e.target.value)}
                                            >
                                                <MenuItem value={"بله"}>بله</MenuItem>
                                                <MenuItem value={"نه"}>نه</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>شماره تذکره</Typography>
                                        <TextField placeholder='شماره تذکره' value={personalInfo.idCard}
                                            onChange={(e) => handlePersonalInfoChange('idCard', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>کتابچه راهنمای نگهداری</Typography>
                                        <TextField placeholder='کتابچه راهنمای نگهداری' value={personalInfo.MaintenanceHandBook}
                                            onChange={(e) => handlePersonalInfoChange('MaintenanceHandBook', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>

                                </Grid>
                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>خون اهدا کنید</Typography>
                                        <TextField placeholder='خون اهدا کنید' value={personalInfo.bloodDonate}
                                            onChange={(e) => handlePersonalInfoChange('bloodDonate', e.target.value)}
                                            style={{ width: '100%', marginTop: '10px' }} />
                                    </Grid>
                                    <Grid item xs={12} md={5.9}>
                                        <Typography color="textSecondary"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: '600',
                                            }}>تصویر پروفایل</Typography>
                                        <div style={uploadButtonSX} onClick={handleUploadClick}>
                                            <AttachFileIcon sx={{ marginRight: 1 }} />
                                            <Typography color="textSecondary"
                                                sx={{
                                                    fontSize: "16px",
                                                    fontWeight: "400",
                                                    // mt: 1,
                                                    // padding: "10px"
                                                }}>{profileImage ? profileImage.name : 'یک فایل تصویری را انتخاب کنید'}</Typography>
                                        </div>
                                        <TextField
                                            id="image-upload-input"
                                            type="file"
                                            accept="image/*"
                                            style={inputSX}
                                            onChange={handleImageChange}
                                            ref={imageInputRef}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5.9} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        {/* <div> </div> */}
                                        {isPersonalInfoComplete() && (

                                            < img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                        )}
                                    </Grid>
                                </Grid>

                            </CardContent>) :
                            steps === 1 ?
                                (
                                    <CardContent>
                                        <Typography variant="h3">معلومات تماس</Typography>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>شماره تلفن (تلفن ثابت)</Typography>
                                                <TextField placeholder='شماره تلفن (تلفن ثابت)'
                                                    value={contactInfo.phoneLandline}
                                                    onChange={(e) => handleContactInfoChange('phoneLandline', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>شماره تلفن (موبایل)</Typography>
                                                <TextField placeholder='شماره تلفن (موبایل)'
                                                    value={contactInfo.phoneMobile}
                                                    onChange={(e) => handleContactInfoChange('phoneMobile', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>آدرس ایمیل</Typography>
                                                <TextField placeholder='آدرس ایمیل'
                                                    value={contactInfo.email}
                                                    onChange={(e) => handleContactInfoChange('email', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>آدرس کامل خانه</Typography>
                                                <TextField placeholder='آدرس کامل خانه'
                                                    value={contactInfo.homeAddress}
                                                    onChange={(e) => handleContactInfoChange('homeAddress', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>

                                        </Grid>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                            </Grid>
                                            {isContactInfoComplete() && (

                                                <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                            )}
                                        </Grid>
                                    </CardContent>
                                )
                                : steps === 2 ? (
                                    <CardContent>
                                        <Typography variant="h3">معلومات خانواده</Typography>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>تعداد اعضای خانواده</Typography>
                                                <TextField placeholder='تعداد اعضای خانواده'
                                                    value={familyInfo.FamilyMemeber}
                                                    onChange={(e) => handleFamilyInfoChange('FamilyMemeber', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>نام و سن اعضای خانواده</Typography>
                                                <TextField placeholder='نام و سن اعضای خانواده'
                                                    value={familyInfo.Ages}
                                                    onChange={(e) => handleFamilyInfoChange('Ages', e.target.value)}
                                                    style={{ width: '100%', marginTop: '10px' }} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>وضعیت تحصیلی اعضای خانواده</Typography>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        // labelId="demo-simple-select-label"
                                                        // id="demo-simple-select"
                                                        value={familyEducation}
                                                        // label="Marital"
                                                        onChange={(e) => setFamilyEducatiion(e.target.value)}
                                                    >
                                                        <MenuItem value={"بی سواد"}>بی سواد</MenuItem>
                                                        <MenuItem value={"اولیه"}>اولیه</MenuItem>
                                                        <MenuItem value={"متوسطه"}>متوسطه</MenuItem>
                                                        <MenuItem value={"متوسطه عالی"}>متوسطه عالی</MenuItem>
                                                        <MenuItem value={"فارغ التحصیل"}>فارغ التحصیل</MenuItem>
                                                        <MenuItem value={"فوق لیسانس"}>فوق لیسانس</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>وضعیت اشتغال اعضای خانواده</Typography>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        // labelId="demo-simple-select-label"
                                                        // id="demo-simple-select"
                                                        value={employmentStatus}
                                                        // label="Marital"
                                                        onChange={(e) => setEmploymentStatus(e.target.value)}
                                                    >
                                                        <MenuItem value={"استخدام شده"}>استخدام شده</MenuItem>
                                                        <MenuItem value={"بیکار"}>بیکار</MenuItem>
                                                        <MenuItem value={"دانشجو"}>دانشجو</MenuItem>
                                                        <MenuItem value={"بازنشسته"}>بازنشسته</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <Typography color="textSecondary"
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: '600',
                                                    }}>رابطه</Typography>
                                                <FormControl fullWidth>
                                                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                                    <Select
                                                        // labelId="demo-simple-select-label"
                                                        // id="demo-simple-select"
                                                        value={relationship}
                                                        // label="Marital"
                                                        onChange={(e) => setRelationship(e.target.value)}
                                                    >
                                                        <MenuItem value={"پدر"}>پدر</MenuItem>
                                                        <MenuItem value={"مادر"}>مادر</MenuItem>
                                                        <MenuItem value={"همسر"}>همسر</MenuItem>
                                                        <MenuItem value={"طفل"}>طفل</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Grid item xs={12} md={5.9}>
                                                <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                            </Grid>
                                            {isFamilyInfoComplete() && (

                                                <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                            )}
                                        </Grid>
                                    </CardContent>
                                )
                                    : steps === 3 ? (
                                        <CardContent>
                                            <Typography variant="h3">اطلاعات اقتصادی</Typography>
                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Grid item xs={12} md={5.9}>
                                                    <Typography color="textSecondary"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>وظیفه و درآمد ماهانه</Typography>
                                                    <TextField placeholder='وظیفه و درآمد ماهانه'
                                                        value={ecoInfo.monthlyIncome}
                                                        onChange={(e) => handleEcoInfoChange("monthlyIncome", e.target.value)}
                                                        style={{ width: '100%', marginTop: '10px' }} />
                                                </Grid>
                                                <Grid item xs={12} md={5.9}>
                                                    <Typography color="textSecondary"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>نوع وظیفه</Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={typeJob}
                                                            onChange={(e) => setTypeJob(e.target.value)}
                                                        >
                                                            <MenuItem value={"حکومت"}>حکومت</MenuItem>
                                                            <MenuItem value={"خصوصی"}>خصوصی</MenuItem>
                                                            <MenuItem value={"فریلنسر"}>فریلنسر</MenuItem>
                                                            <MenuItem value={"بیکار"}>بیکار</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Grid item xs={12} md={5.9}>
                                                    <Typography color="textSecondary"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>وضعیت اشتغال</Typography>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={ecoEmploymentStatus}
                                                            onChange={(e) => setEcoEmploymentStatus(e.target.value)}
                                                        >
                                                            <MenuItem value={"حکومت"}>استخدام شده</MenuItem>
                                                            <MenuItem value={"بیکار"}>بیکار</MenuItem>
                                                            <MenuItem value={"خصوصی"}>تجارت پیشه</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={5.9}>
                                                    <Typography color="textSecondary"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>کارفرما (نام شرکت یا سازمان)</Typography>
                                                    <TextField placeholder='کارفرما (نام شرکت یا سازمان)'
                                                        value={ecoInfo.nameCompany}
                                                        onChange={(e) => handleEcoInfoChange("nameCompany", e.target.value)}
                                                        style={{ width: '100%', marginTop: '10px' }} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                <Grid item xs={12} md={5.9}>
                                                    <Typography color="textSecondary"
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>تجربه کاری</Typography>
                                                    <TextField placeholder='تجربه کاری'
                                                        value={ecoInfo.workExprience}
                                                        onChange={(e) => handleEcoInfoChange("workExprience", e.target.value)}
                                                        style={{ width: '100%', marginTop: '10px' }} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Grid item xs={12} md={5.9}>
                                                    <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                </Grid>
                                                {isEcoInfoComplete() && (

                                                    <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                )}
                                            </Grid>
                                        </CardContent>
                                    )
                                        : steps === 4 ?
                                            (
                                                <CardContent>
                                                    <Typography variant="h3">معلومات آموزشی</Typography>
                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xs={12} md={5.9}>
                                                            <Typography color="textSecondary"
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}>سطح تحصیلات</Typography>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    value={educationLevel}
                                                                    onChange={(e) => setEducationLevel(e.target.value)}
                                                                >
                                                                    <MenuItem value={"بدون تحصیلات رسمی"}>بدون تحصیلات رسمی</MenuItem>
                                                                    <MenuItem value={"اولیه"}>اولیه</MenuItem>
                                                                    <MenuItem value={"متوسطه"}>متوسطه</MenuItem>
                                                                    <MenuItem value={"متوسطه عالی"}>متوسطه عالی</MenuItem>
                                                                    <MenuItem value={"فارغ التحصیل"}>فارغ التحصیل</MenuItem>
                                                                    <MenuItem value={"فوق لیسانس"}>فوق لیسانس</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={5.9}>
                                                            <Typography color="textSecondary"
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}>رشته تحصیلی</Typography>
                                                            <TextField placeholder='رشته تحصیلی'
                                                                value={educationInfo.fieldStudy}
                                                                onChange={(e) => handleEducationInfoChange("fieldStudy", e.target.value)}
                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xs={12} md={5.9}>
                                                            <Typography color="textSecondary"
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}>نوعیت و موقعیت تحصیلات</Typography>
                                                            <TextField placeholder='نوعیت و موقعیت تحصیلات'
                                                                value={educationInfo.typeLocationStudy}
                                                                onChange={(e) => handleEducationInfoChange("typeLocationStudy", e.target.value)}
                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                        </Grid>
                                                        <Grid item xs={12} md={5.9}>
                                                            <Typography color="textSecondary"
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}>مدارج تحصیلی</Typography>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    value={educationDegree}
                                                                    onChange={(e) => setEducationDegree(e.target.value)}
                                                                >
                                                                    <MenuItem value={"گواهی"}>گواهی</MenuItem>
                                                                    <MenuItem value={"دیپلوم"}>دیپلوم</MenuItem>
                                                                    <MenuItem value={"مدرک لیسانس"}>مدرک لیسانس</MenuItem>
                                                                    <MenuItem value={"ماستری"}>ماستری</MenuItem>
                                                                    <MenuItem value={"دکترا"}>دکترا</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xs={12} md={5.9}>
                                                            <Typography color="textSecondary"
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    fontWeight: '600',
                                                                }}>تخصص ها و مهارت ها</Typography>
                                                            <TextField placeholder='تخصص ها و مهارت ها'
                                                                value={educationInfo.skills}
                                                                onChange={(e) => handleEducationInfoChange("skills", e.target.value)}
                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                        </Grid>

                                                    </Grid>
                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        <Grid item xs={12} md={5.9}>
                                                            <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                        </Grid>
                                                        {isEducationInfoComplete() && (

                                                            <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                        )}
                                                    </Grid>
                                                </CardContent>
                                            )
                                            : steps === 5 ?
                                                (
                                                    <CardContent>
                                                        <Typography variant="h3">معلومات ملکیت</Typography>
                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Grid item xs={12} md={5.9}>
                                                                <Typography color="textSecondary"
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}>نوع ملکیت</Typography>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        value={propertyType}
                                                                        onChange={(e) => setPropertyType(e.target.value)}
                                                                    >
                                                                        <MenuItem value={"خانه"}>خانه</MenuItem>
                                                                        <MenuItem value={"زمین"}>زمین</MenuItem>
                                                                        <MenuItem value={"اپارتمان"}>اپارتمان</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={5.9}>
                                                                <Typography color="textSecondary"
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}>ساحه ملکیت</Typography>
                                                                <TextField placeholder='ساحه ملکیت'
                                                                    value={propertyInfo.area}
                                                                    onChange={(e) => handlePropertyInfoChange("area", e.target.value)}
                                                                    style={{ width: '100%', marginTop: '10px' }} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Grid item xs={12} md={5.9}>
                                                                <Typography color="textSecondary"
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}>استفاده از ملکیت</Typography>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        value={propertyUsage}
                                                                        onChange={(e) => setPropertyUsage(e.target.value)}
                                                                    >
                                                                        <MenuItem value={"مسکونی"}>مسکونی</MenuItem>
                                                                        <MenuItem value={"تجاری"}>تجاری</MenuItem>
                                                                        <MenuItem value={"زراعتی"}>زراعتی</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} md={5.9}>
                                                                <Typography color="textSecondary"
                                                                    sx={{
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                    }}>وضعیت مالکیت</Typography>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        value={ownershipStatus}
                                                                        onChange={(e) => setOwnershipStatus(e.target.value)}
                                                                    >
                                                                        <MenuItem value={"مالک"}>مالک</MenuItem>
                                                                        <MenuItem value={"مستاجر"}>مستاجر</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Grid item xs={12} md={5.9}>
                                                                <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                            </Grid>
                                                            {isPropertyInfoComplete() && (
                                                                <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                            )}
                                                        </Grid>
                                                    </CardContent>
                                                ) : steps === 6 ?
                                                    (
                                                        <CardContent>
                                                            <Typography variant="h3">معلومات صحی</Typography>
                                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Grid item xs={12} md={5.9}>
                                                                    <Typography color="textSecondary"
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}>وضعیت صحی و پزشکی</Typography>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            value={healthStatus}
                                                                            onChange={(e) => setHealthStatus(e.target.value)}
                                                                        >
                                                                            <MenuItem value={"صحت"}>صحت</MenuItem>
                                                                            <MenuItem value={"امراض مزمن"}>امراض مزمن</MenuItem>
                                                                            <MenuItem value={"معلولیت ها"}>معلولیت ها</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} md={5.9}>
                                                                    <Typography color="textSecondary"
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}>دسترسی به خدمات صحی</Typography>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            value={healthService}
                                                                            onChange={(e) => setHealthService(e.target.value)}
                                                                        >
                                                                            <MenuItem value={"شفاخانه ها"}>شفاخانه ها</MenuItem>
                                                                            <MenuItem value={"کلینیک ها"}>کلینیک ها</MenuItem>
                                                                            <MenuItem value={"هیچ"}>هیچ</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                            </Grid>
                                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Grid item xs={12} md={5.9}>
                                                                    <Typography color="textSecondary"
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            fontWeight: '600',
                                                                        }}>بیمه صحی</Typography>
                                                                    <FormControl fullWidth>
                                                                        <Select
                                                                            value={healthInsurance}
                                                                            onChange={(e) => setHealthInsurance(e.target.value)}
                                                                        >
                                                                            <MenuItem value={"بله"}>بله</MenuItem>
                                                                            <MenuItem value={"نه"}>نه</MenuItem>
                                                                            <MenuItem value={"نوع بیمه"}>نوع بیمه</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                <Grid item xs={12} md={5.9}>
                                                                    <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                                </Grid>
                                                                {isHealthInfoComplete() && (
                                                                    <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                                )}
                                                            </Grid>
                                                        </CardContent>
                                                    ) : steps === 7 ?
                                                        (
                                                            <CardContent>
                                                                <Typography variant="h3">اطلاعات اجتماعی</Typography>

                                                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Grid item xs={12} md={5.9}>
                                                                        <Typography color="textSecondary"
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}>وضعیت اجتماعی</Typography>
                                                                        <FormControl fullWidth>
                                                                            <Select
                                                                                value={socialStatus}
                                                                                onChange={(e) => setSocialStatus(e.target.value)}
                                                                            >
                                                                                <MenuItem value={"طبیعی"}>طبیعی</MenuItem>
                                                                                <MenuItem value={"معلول"}>معلول</MenuItem>
                                                                                <MenuItem value={"نیازهای خاص"}>نیازهای خاص</MenuItem>
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={5.9}>
                                                                        <Typography color="textSecondary"
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}>عضویت در انجمن ها و گروه های اجتماعی</Typography>
                                                                        <TextField placeholder='عضویت در انجمن ها و گروه های اجتماعی'
                                                                            value={socialInfo.memberShipGroup}
                                                                            onChange={(e) => handleSocialInfoChange("memberShipGroup", e.target.value)}
                                                                            style={{ width: '100%', marginTop: '10px' }} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Grid item xs={12} md={5.9}>
                                                                        <Typography color="textSecondary"
                                                                            sx={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}>فعالیت های داوطلبانه و خیریه</Typography>
                                                                        <TextField placeholder='فعالیت های داوطلبانه و خیریه'
                                                                            value={socialInfo.charityActivity}
                                                                            onChange={(e) => handleSocialInfoChange("charityActivity", e.target.value)}
                                                                            style={{ width: '100%', marginTop: '10px' }} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Grid item xs={12} md={5.9}>
                                                                        <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                                    </Grid>
                                                                    {isSocialInfoComplete() && (
                                                                        <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                                    )}
                                                                </Grid>
                                                            </CardContent>
                                                        ) : steps === 8 ?
                                                            (
                                                                <CardContent>
                                                                    <Typography variant="h3">اطلاعات امنیتی</Typography>

                                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <Grid item xs={12} md={5.9}>
                                                                            <Typography color="textSecondary"
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}>سابقه جنایی</Typography>
                                                                            <FormControl fullWidth>
                                                                                <Select
                                                                                    value={criminal}
                                                                                    onChange={(e) => setCriminal(e.target.value)}
                                                                                >
                                                                                    <MenuItem value={"بله"}>بله</MenuItem>
                                                                                    <MenuItem value={"نه"}>نه</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12} md={5.9}>
                                                                            <Typography color="textSecondary"
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}>معلومات تماس اضطراری</Typography>
                                                                            <TextField placeholder='معلومات تماس اضطراری'
                                                                                value={securityInfo.name}
                                                                                onChange={(e) => handleSecurityInfoChange("name", e.target.value)}
                                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                                                                        <Grid item xs={12} md={5.9}>
                                                                            <Typography color="textSecondary"
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}>رابطه</Typography>
                                                                            <TextField placeholder='رابطه'
                                                                                value={securityInfo.relationship}
                                                                                onChange={(e) => handleSecurityInfoChange("relationship", e.target.value)}
                                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                                        </Grid>
                                                                        <Grid item xs={12} md={5.9}>
                                                                            <Typography color="textSecondary"
                                                                                sx={{
                                                                                    fontSize: '16px',
                                                                                    fontWeight: '600',
                                                                                }}>شماره تلفن</Typography>
                                                                            <TextField placeholder='شماره تلفن'
                                                                                value={securityInfo.phoneNumber}
                                                                                onChange={(e) => handleSecurityInfoChange("phoneNumber", e.target.value)}
                                                                                style={{ width: '100%', marginTop: '10px' }} />
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <Grid item xs={12} md={5.9}>
                                                                            <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                                        </Grid>
                                                                        {isEmergencyInfoComplete() && (
                                                                            <img src={Next} alt='' style={{ cursor: "pointer" }} onClick={nextStep} />
                                                                        )}
                                                                    </Grid>
                                                                </CardContent>
                                                            ) : steps === 9 ?
                                                                (
                                                                    <CardContent>
                                                                        <Typography variant="h3">معلومات اضافی</Typography>

                                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <Grid item xs={12} md={5.9}>
                                                                                <Typography color="textSecondary"
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '600',
                                                                                    }}>دسترسی به انترنت و تکنالوژی معلوماتی</Typography>
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        value={accessInternet}
                                                                                        onChange={(e) => setAccessInternet(e.target.value)}
                                                                                    >
                                                                                        <MenuItem value={"بله"}>بله</MenuItem>
                                                                                        <MenuItem value={"نه"}>نه</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            <Grid item xs={12} md={5.9}>
                                                                                <Typography color="textSecondary"
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '600',
                                                                                    }}>موترهای شخصی</Typography>

                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        value={vehicles}
                                                                                        onChange={(e) => setVehicles(e.target.value)}
                                                                                    >
                                                                                        <MenuItem value={"هیچ"}>هیچ</MenuItem>
                                                                                        <MenuItem value={"موتر"}>موتر</MenuItem>
                                                                                        <MenuItem value={"موترسایکل"}>موترسایکل</MenuItem>
                                                                                        <MenuItem value={"دوچرخه"}>دوچرخه</MenuItem>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <Grid item xs={12} md={5.9}>
                                                                                <Typography color="textSecondary"
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '600',
                                                                                    }}>نمبر پلیت</Typography>
                                                                                <TextField placeholder='نمبر پلیت'
                                                                                    value={additionalInfo.plateNumber}
                                                                                    onChange={(e) => handleAdditionalInfoChange("plateNumber", e.target.value)}
                                                                                    style={{ width: '100%', marginTop: '10px' }} />
                                                                            </Grid>
                                                                            <Grid item xs={12} md={5.9}>
                                                                                <Typography color="textSecondary"
                                                                                    sx={{
                                                                                        fontSize: '16px',
                                                                                        fontWeight: '600',
                                                                                    }}>سرگرمی ها و علایق</Typography>
                                                                                <TextField placeholder='سرگرمی ها و علایق'
                                                                                    value={additionalInfo.hobby}
                                                                                    onChange={(e) => handleAdditionalInfoChange("hobby", e.target.value)}
                                                                                    style={{ width: '100%', marginTop: '10px' }} />
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid container spacing={0} style={{ marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <Grid item xs={12} md={5.9}>
                                                                                <img src={Previous} alt='' style={{ cursor: "pointer" }} onClick={PreviousStep} />
                                                                            </Grid>
                                                                            {isAdditionalInfoComplete() && (
                                                                                <Button variant='contained' onClick={InfoDone}>Done</Button>
                                                                            )}
                                                                        </Grid>
                                                                    </CardContent>
                                                                ) : null}

                    </Card>
                </Box>
            </div>
        </LocalizationProvider >
    );
}

export default CreateProfile;
