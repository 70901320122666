import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Grid } from "@mui/material";

import user1 from "../../../assets/images/backgrounds/u2.jpg";
import user2 from "../../../assets/images/backgrounds/u3.jpg";
import user3 from "../../../assets/images/backgrounds/u4.jpg";
import { backendLink, imageBackendLink } from "../../../env";
import { useNavigate } from "react-router-dom";

const blogs = [
  {
    img: user1,
    title: "Super awesome, Angular 12 is coming soon!",
    subtitle:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    btncolor: "error",
  },
  {
    img: user2,
    title: "Super awesome, Angular 12 is coming soon!",
    subtitle:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    btncolor: "warning",
  },
  {
    img: user3,
    title: "Super awesome, Angular 12 is coming soon!",
    subtitle:
      "Some quick example text to build on the card title and make up the bulk of the card's content.",
    btncolor: "primary",
  },
];

const News = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [getAllData, setGetAllData] = useState([])
  const FetchAllData = async () => {
    try {
      // setLoader(true)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow"
      };
      // const response = await fetch('https://villageconnect.codexsmart.com/api/v1/VideoUpload/Add',
      const response = await fetch(`${backendLink}/News/Get`,
        requestOptions
      );

      const data = await response.json();

      if (response.ok) {
        setLoader(false)
        // toast.success(data.message);
        console.log(data);
        setGetAllData(data.news)
      } else {
        setLoader(false)
        // toast.error('Error: ' + (data.message || 'Something went wrong'));
      }
    } catch (error) {
      setLoader(false)
      // toast.error('Error uploading video. Please try again.');
    }
  }
  useEffect(() => {
    FetchAllData()
  }, [])
  return (
    <Grid container>
      {getAllData.map((blog, index) => (
        <Grid
          key={index}
          item
          xs={12}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "stretch",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              p: 0,
              width: "100%",
              // height:"400px"
            }}
          >
            <img src={`${imageBackendLink}${blog.image}`} alt="img" width="100%" />
            <CardContent
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "h4.fontSize",
                  fontWeight: "600",
                  textAlign: "end"
                }}
              >
                {blog.title}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  mt: 1,
                  textAlign: "end",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 2,
                }}
                dangerouslySetInnerHTML={{ __html: blog.description }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                <Button
                  variant="contained"
                  sx={{
                    mt: "15px",
                    fontWeight: "600",
                    fontSize: "16px"
                  }}
                  color={blog.btncolor}
                  onClick={() =>navigate('/MoreNews',{state:blog})}
                >
                  بیشتر بخوانید
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default News;
