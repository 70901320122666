import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, Card, CardContent, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function ChartComponent({ jobTypeData, data, heading }) {
    const finalData = {
        labels: data.map((item) => item.label),
        datasets: [
            {
                data: data.map((item) => item.value),
                backgroundColor: data.map((item) => item.color),
                borderColor: data.map((item) => item.color),
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                labels: {
                    font: {
                        size: 18, // Increase legend label font size
                    },
                },

            },
        },
        cutout: "80%", // Adjust the cutout percentage as needed
    };

    return (
        <Card variant="outlined" sx={{ paddingBottom: "0" }}>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
                <Box sx={{ display: { sm: "flex", xs: "block" }, alignItems: "center" }}>
                    <Box>
                        <Typography
                            variant="h3"
                            sx={{ marginBottom: "0" }}
                            gutterBottom
                        >
                            {heading}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ marginTop: "25px" }}>
                    <Doughnut data={finalData} options={options} />
                </Box>
            </CardContent>
        </Card>
    );
}

export default ChartComponent;
